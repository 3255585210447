.lia-card-membership-badge {
  display: inline-flex;
  align-items: center;
  color: var(--lia-bs-gray-700);
  padding: 0;
  margin-top: 10px;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--lia-bs-white);
  }
}
