.lia-node-member-count {
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  &-icon {
    margin-right: 5px;
  }
}
